<template>
	<a
		v-if="Boolean($props.href)"
		:href="$props.href || $props.to"
		:title="$props.title"
		target="_blank"
		rel="nofollow noreferrer noopener"
		:class="[inheritColor ? 'inheritColor' : colorClass]"
		v-bind="$attrs"
	>
		<slot />

		<span class="sr-only">Opens a new window</span>
	</a>

	<RouterLink
		v-else
		v-slot="{ isActive, href: hrefFromRouterLink, navigate }"
		v-bind="$props"
		custom
	>
		<div :class="[disabled ? 'cursor-not-allowed' : '']">
			<a
				:aria-current="isActive ? 'page' : null"
				:class="[
					{
						[$props.activeClass]: isActive,
					},
					inheritColor ? 'inheritColor' : colorClass,
					disabled ? 'pointer-events-none' : '',
				]"
				:aria-disabled="disabled"
				target="_self"
				:href="hrefFromRouterLink"
				v-bind="$attrs"
				@click="navigate"
				@keydown.enter="disabled ? $event.preventDefault() : navigate"
			>
				<slot
					v-bind="{
						isActive,
					}"
				/>
			</a>
		</div>
	</RouterLink>
</template>

<script setup>
/*
  Tweaked example from vue-router docs:
  https://router.vuejs.org/guide/advanced/extending-router-link.html
*/
import { computed } from 'vue';
import { RouterLink } from 'vue-router';

import { getColorClass } from '@/utils/styles';

defineOptions({
	inheritAttrs: false,
});

const props = defineProps({
	...RouterLink.props,

	to: {
		type: Object,
		default: null,
		required: false,
		validator(value) {
			if (typeof value === 'string') {
				console.warn(
					`The "to" prop received the string ${value}, which should be passed to the href prop instead.`
				);
				return false;
			}
			return true;
		},
	},
	href: { type: String, default: null },

	disabled: { type: Boolean, default: false },
	color: { type: String, default: null },
	title: { type: String, default: null },
	inheritColor: { type: Boolean, default: false },
	activeClass: { type: String, default: null },
});

const colorClass = computed(() =>
	props.inheritColor ? '' : getColorClass(props.color, 'mirusOrange')
);
</script>
